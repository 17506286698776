@import "../../pages/config.scss";

.container-ready {
  
  margin-left: auto;
  margin-right: auto;
  .img-container {
    position: relative;
    right: 0.5rem;
  }
  .img-container > img {
    width: 45%;
  }
}
