// Override default variables before the import here
$brand-primary: #f7fff7;
$brand-secondary: #f56329;

// Import Bootstrap and its default variables
// @import '~bootstrap/scss/bootstrap.scss';
@import "node_modules/bootstrap/scss/bootstrap.scss";

// Add custom files here
@import "./components/Nav.scss";

.page {
  background-color: $brand-primary;
  min-height: "fit-content";
  min-height: 100%;


  .jumbotron {
    background-color: $brand-primary;
    text-align: center;
  }
}

.btn-primary {
  background-color: $brand-secondary;
  border-color: $brand-secondary;

  &:hover {
    background-color: $brand-secondary;
    border-color: $brand-secondary;
  
}

}

.content {
  margin: auto;
  // margin-bottom: 0;
  @media only screen and (max-width: 990px) {
    margin-bottom: 5rem;
  }
}
