@import "../../pages/config.scss";

.btn-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;

  .btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
  }
  .btn-secondary {
    background-color: $secondary-color;
    border-color: $secondary-color;
  }
  .rec-container {
    align-items: center;
    display: flex;
    margin: 0 3rem;

    .rec {
      margin: 5px;
      height: 10px;
      width: 10px;
      background-color: $grey-color;
      border-radius: 50%;
    }
    .active {
      background-color: $secondary-color;
    }
  }
}
