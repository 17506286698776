@import "../../pages/config.scss";

.row {
  margin-left: 0px;
}
.container-height {
  position: relative;
  height: 70vh;
  width: 100%;
  padding-top: 8rem;

  bottom: 7rem;
  background-image: url("/assets/image/height_background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% -35vh;

  .input-box {
    display: flex;
    flex-direction: column;
  }

  .height-text-box {
    left: 5vw;
  }
  .container-img {
    position: relative;
    right: 20vw;
    img {
      height: 100%;
    }
  }

  h3 {
    color: $text-color;
    font-weight: bold;
  }

  input {
    height: 3rem;
    width: 4rem;
    color: $primary-color;
    font-weight: 700;
    font-size: 2rem;
    border: 0;
    border-bottom: 2px solid $text-color;
    margin-right: 0.5rem;
  }
  input:focus {
    outline: none;
  }

  .unit-text {
    font-weight: bold;
    font-size: 1.5rem;
    color: $text-color;
  }
}
