@import "./config.scss";

.login {
  height: 100vh;
  background-color: $white-color;
  background-image: url("/assets/image/background.svg");
  background-repeat: no-repeat;
  background-size: 58vw auto;
  background-position: top left;

  .input-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .input-form {
    margin-top: 3rem;
    height: 40vh;
  }

  .form-group {
    margin: 2rem;
  }

  .txt-white {
    color: $white-color;
  }

  .txt-primary {
    color: $primary-color;
  }

  a {
    color: $primary-color;
    &:hover {
      text-decoration: none;
    }
  }
  .btn-container {
    margin: 2rem;
  }
  .btn-login {
    color: $white-color;
    background-color: $secondary-color;
  }
  .btn-3rdauth {
    color: $white-color;
    background-color: $primary-color;
  }
  i {
    margin-left: 1rem;
    font-size: 1.5rem;
  }
}
