// Todo- import primary colors and replace color
@import "./JFRCard.scss";

.card {
  @extend .card;
  color: $text-color;
  font-size: larger;
  .row {
    margin: 0.5rem 0;
  }
  .title-head {
    font-weight: bold;
    text-align: left;
  }
  .calculator-label {
    font-size: 1rem;
    text-align: start;
  }

  select {
    color: $text-color;
    border: 0;
    border-bottom: 1px solid $text-color;
    text-align-last: center;
    width: inherit;
    &:focus {
      outline: none;
    }
  }

  // .fitlevel-container {
  //   position: relative;
  //   bottom: 1.5rem;
  // }
  // .fitlevel-text {
  //   color: $secondary-color;
  //   position: relative;
  //   bottom: 1.5rem;
  // }

  // .sliderContainer {
  //   width: 75%;
  //   .slider {
  //     -webkit-appearance: none;
  //     width: 100%;
  //     height: 10px;
  //     background: linear-gradient(
  //       90deg,
  //       $secondary-color 60%,
  //       rgb(214, 214, 214) 60%
  //     );
  //     outline: none;
  //     opacity: 0.7;
  //     -webkit-transition: 0.2s;
  //     transition: opacity 0.2s;
  //     border-radius: 12px;
  //     box-shadow: 0px1px 10px 1px black;

  //     &::-webkit-slider-thumb {
  //       -webkit-appearance: none;
  //       appearance: none;
  //       width: 25px;
  //       height: 25px;
  //       background: $secondary-color;
  //       border-radius: 50%;
  //       cursor: pointer;
  //     }

  //     &::-moz-range-thumb {
  //     }

  //     &:hover {
  //       opacity: 1;
  //     }
  //   }
  // }
  .tooltip {
    position: relative;
    display: inline-block;

    i {
      color: $secondary-color;
    }

    opacity: 1;

    .tooltiptext {
      visibility: hidden;
      width: 120px;
      background-color: #555;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;

      /* Position the tooltip text */
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      margin-left: -60px;

      /* Fade in tooltip */
      opacity: 1;
      transition: opacity 0.3s;
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }
    }
    &:hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }
}
