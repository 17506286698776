@import "./config.scss";

.container-onboarding {
  text-align: center;
  height: 86.6vh;
  margin-left:auto;
  margin-right:auto;
  padding-bottom: 1%;
  width:100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: $white-color;

  .content-useronboarding {
    padding-top: 3rem;
  }

  .color-text {
    color: $text-color;
  }
  .color-primary {
    color: $primary-color;
  }
}

@media only screen and (min-width: 1600px) {
  .container-onboarding {
    padding-top: 5rem;
  }
}
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    .container-onboarding {
      padding-bottom: 10%;
    }
}