@import "../../pages/config.scss";

.calculate {
  margin: auto;
  position: relative;
  width: 100vw;
  max-width: 1200px;
  height: 70vh;
  padding-top: 7rem;
  top: -5rem;
  background-image: url("/assets/image/combined-shape.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% -40vh;
  color: $text-color;

  .top-text {
    color: $white-color;
  }

  .container-input {
    margin-top: 3rem;
  }
}
