@import "../../pages/config.scss";

.dob {
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
  width: 100vw;
  height: 100vh;
  padding-top: 20%;
  top: -3rem;
  background-image: url("/assets/image/combined-shape.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% -50vh;
  .container-img {
    margin-left: auto;
    margin-right: auto;
  }
  .container-img > img {
    width: 100%;
    align-items: center;
  }

  h3 {
    color: $text-color;
  }

  input {
    height: 4rem;
    background-color: rgb(240, 228, 228);
    color: $primary-color;
    font-weight: 700;
    font-size: 2rem;
    border: 0;
    outline: none;
    border-bottom: 2px solid $text-color;
    text-indent: 1rem;
  }
}
  @media only screen and (min-width: 767px) {
    .dob {
      position: relative;
      width: 100%;
    }
  }

  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    
  }
