@import "./JFRCard.scss";
$brand-primary: #f56329;
.headline {
  color: $primary-color;
  font-weight: 700;
  font-size: 1.4rem;
}
.button:hover {
  background-color: $brand-primary;
  color: white;
  cursor: pointer;
}
.card {
  .measurementBox {
    .measurement {
      div {
        div {
          display: inline-block;
          padding: 0.5rem 1rem;
          // margin: 0.25rem 1rem;
          min-width: 5rem;
          text-align: center;
        }
      }

      .name {
        color: $primary-color;
        width: 10rem;
        border: none;
        font-weight: 500;
      }
    }
  }
}
