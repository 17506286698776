@import "./config.scss";

.home {
  height: 100vh;
  background-color: $white-color;
  background-image: url("/assets/image/background.svg");
  background-repeat: no-repeat;
  background-size: 58vw auto;
  background-position: top left;

  .backgroundTr {
    padding-top: 3rem;
  }

  @media only screen and (max-width: 579px) {
    background-size: 65vw auto;
    .backgroundTr {
      padding-top: 0px;
    }
    h1 {
      font-size: 2rem !important;
    }

    .input-form {
      margin-top: 0px !important;
    }
  }

  //for backgroud triangle locating between title"junkfood rehab"
  @media only screen and (min-width: 580px) {
    background-size: 63vw auto;
  }

  @media only screen and (min-width: 767px) {
    background-size: 58vw auto;
  }

  @media only screen and (min-width: 1291px) {
    background-size: 56vw auto;
  }

  .container-home {
  }

  .input-container {
    margin-top: 8vh !important;
  }

  .input-form {
    margin-top: 3rem;
    height: 40vh;
  }

  .img-container {
    margin-bottom: 1rem;
  }

  .txt-white {
    color: $white-color;
  }

  .txt-primary {
    color: $primary-color;
  }

  a {
    color: $primary-color;
    &:hover {
      text-decoration: none;
    }
  }
  .btn-login {
    color: $white-color;
    background-color: $secondary-color;
  }
  .btn-container {
    margin: 2rem;
  }
}
