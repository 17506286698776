.snapnav {
  background: #f56329;
  padding: 1.5rem 0;
  color: white;
  text-align: center;
  ul {
    display: inline-flex;
    list-style-type: none;
    width: 100%;
    font-size: 11px;

    li {
      width: 25%;
      a {
        color: white;
      }
    }
  }
  z-index: 200;

  @media only screen and (max-width: 990px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  @media only screen and (min-width: 991px) {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
  }
  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    
  }
}
