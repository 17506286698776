// Todo- import primary colors and replace color
@import "./JFRCard.scss";

.measurementChart {
  @extend .card;

  div:first-of-type {
    font-weight: 700;
    padding: 0;
    color: $primary-color;
  }

  div {
    padding-left: 0.5rem;
    margin: 0.5rem;
    color: gray;
  }

  .legend-item-1 {
    margin-left: 1vw;
    color: $secondary-color;
  }

  .recharts-legend-wrapper {
    top: -10px !important;
  }
  @media only screen and (max-width: 845px) {
    //to move the chart left on smaller screen
    .chart-container {
      padding-left: -2rem;
      margin-left: -2rem;
    }
  }
}
