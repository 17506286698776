@import "../../pages/config.scss";

.card {
  margin: 2rem;
  padding: 2rem;
  border-radius: 2rem;
}

.title {
  color: $primary-color;
  font-weight: 700;
  font-size: 1.4rem;
}
