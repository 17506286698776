@import "../pages/config.scss";

.intro {
  padding: 2rem 0;
  background-color: #f56329;
  align-items: center;
}
.contain {
  margin: 0;
  background-color: whitesmoke;
  color: grey;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 40px;
  background-color: blue($color: #000000);
  color: white;
  text-align: center;
}
h3 {
  font-family: sans-serif;
  font-weight: bold;
  margin-top: 0;
  color: #f56329;
}

.card1 {
  align-content: flex-start;
  background-color: white;
  //align-self: center;
  margin: 4rem;
  border-radius: 0.5rem;
  padding: 60px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.knob {
  > div {
    text-align: center;
    > div {
      display: inline-block;
    }
  }

  .knobLabel {
    font-size: 2rem;
    font-weight: 700;
    color: #f56329;
  }
}
