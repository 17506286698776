@import "../../pages/config.scss";

.container-weight {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  bottom: 5%;
  height: 70vh;
  width: 80vh;
  background-image: url("/assets/image/workout_girl.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 30%;

  h3 {
    color: $text-color;
  }

  input {
    height: 3rem;
    width: 4rem;
    color: $primary-color;
    font-weight: 700;
    font-size: 2rem;
    border: 0;
    border-bottom: 2px solid $text-color;
    margin-right: 0.5rem;
  }
  input:focus {
    outline: none;
  }

  .unit-text {
    font-size: 2rem;
    color: $text-color;
  }
}
@media only screen and (max-width: 650px) {
  .container-weight {
    position: relative;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }
}
