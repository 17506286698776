@import "../../pages/config.scss";

.container > div {
  height: 70vh;
}
.container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  object-fit: contain;
  height: 100vh;

  @media only screen and (min-width: 767px) {
    position: relative;
    width: 100%;
  }

  .container-sex {
    height: 20vh;
  }
  
  .container-img > img {
    height: 50vh;
    width: 100vh;
  }

  .btn {
    width: 10rem;
    position: relative;
  }
  .btn-secondary {
    background-color: $secondary-color;
    border-color: $secondary-color;
  }
  .btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  img {
    margin-top: 2rem;
  }
}
